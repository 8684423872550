<template>
<div>
  <div class="barra"></div>

  <div class="row">
    <div class="col- col-sm-12 col-md-6 col-lg-5 col-xl-4">
      <div class="card cardl text-center border-primary mb-3">
        <div class="card-header">
          <h5>Inicia sesión con tu cuenta</h5>
        </div>
        <div class="card-body">
          <form method="POST" action ref="form" >
            <div class="form-group row">
              <div class="col">
                <input
                  placeholder="Correo electronico"
                  type="email"
                  id="email"
                  name="email"
                  class="form-control"
                  v-model="email"
                  required
                  autofocus
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <input
                  placeholder="Contraseña"
                  id="password"
                  type="password"
                  name="password"
                  class="form-control"
                  v-model="password"
                  hint="At least 8 characters"
                  required
                  v-on:keyup.enter="login"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <div class="form-check float-left">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="remember"
                    id="remember"
                    v-model="checkbox"
                    :rules="[(v) => !!v || 'You must agree to continue!']"
                  />
                  <label class="form-check-label" for="remember"
                    >Recordar</label
                  >
                </div>
              </div>
            </div>
            <div class="alert alert-warning" v-if="res" role="alert">
              {{res}}
            </div>
            <div class="form-group">
              <div v-if="validationErrors">
                  <ul class="alert alert-warning">
                      <li v-for="(value, index) in validationErrors" :key="index">{{ value[0] }}</li>
                  </ul>
              </div>
            </div>
            <div class="form-group">
              <ul class="alert alert-warning" v-if="submited">
                  <li v-if="submited && !$v.email.email">Correo electrónico no valido.</li>
                  <li v-if="submited && !$v.email.required">Se requiero un correo electrónico.</li>
                  <li v-if="submited && !$v.password.required">Se requiero una contraseña.</li>
                  <li v-if="submited && !$v.password.minLength">Su contraseña debe tener al menos 6 caracteres.</li>
              </ul>
            </div>

            <div class="form-group row">
              <div class="col">
                <button class="btn btn-primary btn-block btnsend" @click="login">
                  Inicia sesión
                </button>
              </div>
            </div>
            <div class="form-group row">
              <div class="col text-center">
                  <router-link :to="{ name: 'ResetPassword'}" class="btn btn-link btn-block">¿Olvidaste la contraseña?</router-link>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer text-muted">
          ¿Aún no tienes cuenta?
          <router-link to="/register">Registrate aquí</router-link>
        </div>
      </div>
    </div>

    <div class="col mensajer col-sm-12 col-md-6 col-lg-6 col-xl-8">
      <div hidden class="float-right">
        <h1 class="display-4">Lorem ipsum dolor sit.</h1>
        <p class="lead float-right">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit.
        </p>
        <hr class="my-2" />
        <p class="float-right">Lorem.</p>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  name: 'login',
  data: () => ({
    email: "",
    password: "",
    checkbox: false,
    res:'',
    validationErrors: '',
    submited:false
  }),
  validations: {
    password: {
      required,
      minLength: minLength(4)
    },
    email: {
      required,
      email
    }
  },
  methods: {
    login(e) {
      this.submited = true;
      this.res = '';
      e.preventDefault();
      if(this.$v.$invalid){
        return false;
      }
      this.submited = false;
      console.log("btnLogin");
      var btnSend = document.querySelector('.btnsend');
      btnSend.setAttribute('disabled', '');
      this.res = '';
      this.$store
        .dispatch("retrieveToken", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          if(response.data.expires_in){
            this.$router.push({ name: "Courses" });
          } else {
            this.res = 'La dirección de correo electrónico o la contraseña no son válidas. Vuelve a intentarlo.';
            btnSend.removeAttribute('disabled');
          }
        })
        .catch((error) => {
          btnSend.removeAttribute('disabled');
          if (error.response.status == 422){
            this.validationErrors = error.response.data.errors;
          }  
          if (error.response.status == 400){
            this.validationErrors = '';
            this.res = 'La dirección de correo electrónico o la contraseña no son válidas. Vuelve a intentarlo.';
          } 
        });
    },
  },
};
</script>

<style>
.barra {
  height: 100px;
}
</style>
